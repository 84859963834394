
export default {
  props: {
    profile: {
      type: Object,
      default: () => {},
    },
    onlyFictional: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    didLikeProfile() {
      if (this.profile.did_like_profile !== undefined) {
        return this.profile.did_like_profile;
      }

      return (
        this.$store.state.chat.likes?.find((x) => x.id === this.profile.id) !==
        undefined
      );
    },
    likedByProfile() {
      if (this.profile.liked_by_profile !== undefined) {
        return this.profile.liked_by_profile;
      }
      return (
        this.$store.state.chat.liked_by?.find(
          (x) => x.id === this.profile.id
        ) !== undefined
      );
    },
  },
};
