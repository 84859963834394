import { render, staticRenderFns } from "./ProfileOverview.vue?vue&type=template&id=a96a3dbe"
import script from "./ProfileOverview.vue?vue&type=script&lang=js"
export * from "./ProfileOverview.vue?vue&type=script&lang=js"
import style0 from "./ProfileOverview.vue?vue&type=style&index=0&id=a96a3dbe&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SearchProfile: require('/home/code/flirt-frontends/packages/twizie/components/SearchProfile.vue').default,XCamProfile: require('/home/code/flirt-frontends/packages/twizie/components/XCamProfile.vue').default})
