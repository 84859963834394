
export default {
  props: {
    xcamsProfile: {
      type: Object,
      default: null,
    },
    isResellProfile: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    clicked() {
      if (this.isResellProfile) {
        window.open('/api/resellpartner.php?flow=profile-search&redirect=1');
        return;
      }
      window.open(
        '/api/xcams/redirect.php?account=' + this.xcamsProfile.account
      );
    },
  },
};
