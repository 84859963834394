
export default {
  props: {
    linkPage: {
      type: String,
      default: 'members-id',
    },

    profiles: {
      type: Array,
      default: () => [],
    },
    profileColClass: {
      type: String,
      default: 'col-6 col-md-4 col-lg-3',
    },
    suggested: {
      type: Boolean,
      default: false,
    },
    xcamsProfiles: {
      type: Array,
      default: null,
    },
    enableResellCamProfiles: {
      type: Boolean,
      default: true,
    },
    enableCamProfiles: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    xcamsProfileAtIndex(index, real) {
      if (this.xcamsProfiles === null || this.xcamsProfiles.length === 0) {
        return null;
      }
      return (
        this.xcamsProfiles[Math.floor(index / 12) * 2 + (real ? 1 : 0)] || null
      );
    },
    startConversation(selectedprofile) {
      if (this.$auth.loggedIn) {
        const params = {
          profile: selectedprofile.id,
        };

        this.$axios
          .get('/api/chat.php', {
            params,
          })
          .then((res) => {
            const conversation = res.data;
            this.$router.push(
              this.localePath({
                name: 'chats-id',
                params: {
                  id: conversation.id,
                },
              })
            );
          })
          .catch((e) => {
            this.$nuxt.$bvToast.toast(
              'Cannot start chat, try again later or contact customer support',
              {
                title: 'Error',
                autoHideDelay: 5000,
                variant: 'danger',
                appendToast: true,
              }
            );
          });
      }
    },
  },
};
